.container {
    max-width: 1000px;
    margin: 2rem auto;
    padding: 2rem;
    text-align: center;
  }
  
  .projectGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  .projectCard {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .projectImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .projectDetails {
    padding: 1rem;
    text-align: left;
  }
  
  .projectTitle {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .projectDescription {
    font-size: 1rem;
    color: #666;
  }