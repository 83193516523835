.hero {
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
.heroContent {
  background-color: black; /* Semi-transparent background for the content section */
  color: white;
  text-align: center;
  padding: 50px 20px;
}

.heroContent h1 {
  font-size: 3rem;
  margin: 0;
  color: #ffffff;
}

.heroContent p {
  font-size: 1.5rem;
  margin: 1rem 0 0;
  color: #ddd;
}