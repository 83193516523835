.aboutUsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  color: #fff;
  background-color: #999081;
}

.aboutUsImage {
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.heading {
  color: white;
}

.aboutUsContent {
  max-width: 800px;
}

.aboutUsParagraph {
  color: white;
}

p {
  font-size: 1.1rem;
  color: white;
  margin-bottom: 2rem;
}

.banner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(6, 27, 84, 0.8); /* Semi-transparent yellow background */
  padding: 2rem;
  margin: 2rem 0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.bannerSection {
  flex: 1;
  text-align: center;
}

.bannerSection h2 {
  font-size: 1.5rem;
  color: #ffffff;
}

.container {
  max-width: 1000px;
  margin: 2rem auto;
  padding: 2rem;
  text-align: center;
}