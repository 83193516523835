.container {
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f9f9f9; /* Light background color */
  border-radius: 8px;
  text-align: center;
}

.title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.contactInfo {
  display: flex;
  gap: 1.5rem;
  width: 100%;
  justify-content: center;
}

.contactItem {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


.contactText {
  flex: 1;
  padding-right: 1rem;
}

.contactImage {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactImage img {
  width: 250px;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 1.5rem;
  color: #ff6347; 
  margin-bottom: 0.5rem;
}

p {
  font-size: 1.2rem;
  color: #555;
  margin: 0.5rem 0;
}

.email {
  color: black;
}

.phone {
  color: black;
}

.link {
  color: black; /* Standard link color */
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
  color: #DDD2C2;
}